import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-page-header-actions',
  templateUrl: './page-header-actions.component.html',
  styleUrls: ['./page-header-actions.component.scss'],
})
export class PageHeaderActionsComponent {
  @Input() public actions: PageHeaderAction[] = [];
}

export interface PageHeaderAction {
  buttonId?: string;
  label: string;
  icon?: string;
  color?: string;
  callback: Function;
}
