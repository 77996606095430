import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {BigButtonComponent, ButtonComponent, HeaderModule} from 'bs-components';
import {NgxExtendedPdfViewerModule} from 'ngx-extended-pdf-viewer';
import {NgxSpinnerModule} from 'ngx-spinner';
import {CheckTermsComponent} from './components/check-terms/check-terms.component';
import {LogoutComponent} from './components/logout/logout.component';
import {MenuDividerComponent} from './components/menu-divider/menu-divider.component';
import {MessageComponent} from './components/message/message.component';
import {ModuleHomeButtonComponent} from './components/module-home-button/module-home-button.component';
import {ModuleHomePageComponent} from './components/module-home-page/module-home-page.component';
import {NotFoundComponent} from './components/not-found/not-found.component';
import {SettingsComponent} from './components/settings/settings.component';
import {StatusBadgeComponent} from './components/status-badge/status-badge.component';
import {StatusCardComponent} from './components/status-card/status-card.component';
import {FormSectionComponent} from './components/ui/forms/form-section/form-section.component';
import {
  PageHeaderActionsComponent
} from './components/ui/page-header/page-header-actions/page-header-actions.component';
import {
  PageHeaderBackButtonComponent
} from './components/ui/page-header/page-header-back-button/page-header-back-button.component';
import {PageHeaderStatusComponent} from './components/ui/page-header/page-header-status/page-header-status.component';
import {PageHeaderComponent} from './components/ui/page-header/page-header/page-header.component';
import {StatsCardComponent} from './components/ui/stats-card/stats-card.component';
import {LocalDatePipe} from './pipes/localDate.pipe';
import {CopyrightsComponent} from './components/copyrights/copyrights.component';

@NgModule({
  declarations: [
    StatusCardComponent,
    LocalDatePipe,
    StatusBadgeComponent,
    MenuDividerComponent,
    ModuleHomePageComponent,
    ModuleHomeButtonComponent,
    FormSectionComponent,
    NotFoundComponent,
    LogoutComponent,
    SettingsComponent,
    CheckTermsComponent,
    MessageComponent,
    CopyrightsComponent,
    PageHeaderComponent,
    PageHeaderBackButtonComponent,
    PageHeaderStatusComponent,
    PageHeaderActionsComponent,
    StatsCardComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    RouterModule,
    ReactiveFormsModule,
    MatExpansionModule,
    NgxSpinnerModule,
    NgxExtendedPdfViewerModule,
    ButtonComponent,
    HeaderModule,
    BigButtonComponent,
  ],
  exports: [
    StatusCardComponent,
    TranslateModule,
    LocalDatePipe,
    StatusBadgeComponent,
    ReactiveFormsModule,
    MatExpansionModule,
    RouterModule,
    MenuDividerComponent,
    ModuleHomePageComponent,
    ModuleHomeButtonComponent,
    FormSectionComponent,
    NotFoundComponent,
    LogoutComponent,
    SettingsComponent,
    CheckTermsComponent,
    MessageComponent,
    CopyrightsComponent,
    PageHeaderComponent,
    StatsCardComponent,
  ],
})
export class SharedModule {
}
